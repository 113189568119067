import React from 'react';

interface ArrowDownProps {
  className?: string;
  dataHook?: string;
}

export const ArrowDown: React.FC<ArrowDownProps> = ({ className, dataHook }) => (
  <div data-hook={dataHook} className={className}>
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7" viewBox="0 0 12 7">
      <path
        fill="#000"
        fillOpacity=".6"
        fillRule="evenodd"
        d="M10.95.293l.707.707L6 6.657.343 1 1.05.293 6 5.243l4.95-4.95z"
      />
    </svg>
  </div>
);
